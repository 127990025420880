<template>
  <!-- HeroImageOverlay -->

  <div class="relative bg-gray-50 overflow-hidden relative">
    <video
      id="videoDesktop"
      autoplay
      loop
      class="
        w-full
        h-full
        absolute
        top-0
        left-0
        object-cover
        z-10
        hidden
        lg:block
      "
    >
      <source src="/homepage-vid.webm" type="video/webm" />
    </video>
    <video
      id="videoMobile"
      autoplay
      loop
      class="w-full h-full absolute top-0 left-0 object-cover z-10 lg:hidden"
    >
      <source src="/homepage-vid-mob.webm" type="video/webm" />
    </video>
    <div
      class="
        w-full
        h-full
        absolute
        top-0
        left-0
        z-20
        bg-gradient-to-r
        from-transparent
        to-zinc-900
      "
    ></div>
    <div class="max-w-7xl mx-auto relative z-30">
      <div class="relative z-10 lg:max-w-2xl lg:w-full ml-auto">
        <main class="mx-auto max-w-7xl px-6 py-10 sm:py-40">
          <div class="text-center">
            <h1
              class="
                text-4xl
                tracking-tight
                font-extrabold
                text-white
                sm:text-5xl
                md:text-6xl
              "
            >
              <span class="block xl:inline">Hewings Transportation Inc.</span>
            </h1>
            <p
              class="
                mt-6
                mx-auto
                text-white
                font-medium
                sm:mt-5 sm:text-xl sm:max-w-xl sm:mx-auto
                md:mt-5 md:text-2xl
              "
            >
              Your family owned transportation partner.
            </p>
            <div
              class="
                mt-5
                sm:mt-8 sm:flex sm:justify-center
                text-center
                mx-auto
                lg:justify-start
              "
            >
              <div class="rounded-md shadow inline-block mx-auto">
                <router-link to="/contact" className="btn btn-xl"
                  >Get in Touch</router-link
                >
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
  <!-- <HeroImageOverlay>
    <div class="absolute inset-0 w-full h-full overflow-hidden">
      <img
        src="@/assets/big-rig-stylish-industrial-dark-gray-1924650644.jpg"
        alt=""
        class="w-full h-full object-cover object-center"
      />
      <div class="absolute inset-0 bg-accent bg-opacity-80 mix-blend-multiply" aria-hidden="true" />
    </div>
    <Container>
      <div class="py-14">
        <h1 className="text-white ">Hewings <br />Transportation Inc.</h1>
        <p className="text-white max-w-2xl mt-12 text-xl">
          Hewings Transportation Inc is a family-owned company that has been serving customers in
          Southern Ontario for over 25 years. We are based in Branford, Ontario, and operate of a
          custom-built 10,000-sq ft office and warehouse facility with an 8,100 sq-ft service building
          on 6 acres.
        </p>
        <router-link to="/contact" className="btn btn-xl mt-6">Get in Touch</router-link>
      </div>
    </Container>
  </HeroImageOverlay> -->

  <!-- Split -->
  <Split
    title="Who We Are"
    description="We are a medium-sized Canada and US Customs Bonded Carrier with 22 trucks operating between points in Ontario and the United States. We offer trailer load and less-than-trailer load service to and from many States on a daily basis (from Minnesota, Indiana, Wisconsin, Michigan, to Ohio)."
  >
    <img
      className="w-full"
      src="@/assets/who-we-are.webp"
      alt="Happy driver in truck."
    />
  </Split>

  <!-- Split -->
  <Split
    title="Our Services"
    description="Our service also includes warehousing, freight brokerage, LTL and Truckload service to points in southern Ontario. We believe in catering to our customers needs and are not limited to definitive traffic lanes. Our professional and courteous staff and team of skilled and reliable drivers have made us the winning choice among many transportation companies offering similar services."
    :flip="true"
    class=""
  >
    <img
      className="w-full"
      src="@/assets/our-services.webp"
      alt="Truck on highway"
    />
  </Split>

  <!-- Split -->
  <Split
    title="Our Fleet"
    description="Our well maintained fleet of new and up-to-date equipment includes Freightliners, Macks and Volvos, as well as 53' air ride dry van trailers, ensuring that your shipment will get to the destination on schedule and in pristine condition. Our transportation software along with the ELO's ensures minute by minute positioning and immediate updates on pick-ups and deliveries."
  >
    <img
      className="w-full"
      src="@/assets/our-fleet.webp"
      alt="Multiple trucks on highway"
    />
  </Split>

  <SimpleBrandCTA />
  <vimeo-player
    ref="player"
    :video-id="videoID"
    @ready="onReady"
    :player-height="height"
  ></vimeo-player>
</template>

<script>
import SimpleBrandCTA from "@/components/layouts/cta/SimpleBrandCTA.vue";
import Header from "@/components/base/Header";
import HeroImageOverlay from "@/components/layouts/hero/HeroImageOverlay.vue";
import Split from "@/components/layouts/Split.vue";
import Banner from "@/components/layouts/banners/Banner.vue";
import Container from "../../components/layouts/Container.vue";

export default {
  components: {
    Header,
    HeroImageOverlay,
    Split,
    Banner,
    SimpleBrandCTA,
    Container,
  },
  mounted() {
    if (window.innerWidth > 1024) {
      const videoEl = document.getElementById("videoDesktop");
      videoEl.muted = true;
      videoEl.play();
    } else {
      const videoEl = document.getElementById("videoMobile");
      videoEl.muted = true;
      videoEl.play();
    }
  },
};
</script>
